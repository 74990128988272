<template>
  <v-row>
    <v-col
      cols="5"
      md="3"
      xl="5"
      class="pr-0 mr-0"
    >
      <v-select
        v-model="countryCallingCode"
        :items="countryCallingCodes"
        :disabled="disabled"
        :label="labelCountryCallingCode"
        outlined
        item-text="displayText"
        item-value="countryCallingCode"
        required
        :rules="[(v) => !!v || 'Ländervorwahl ist ein Pflichtfeld']"
        :selectable="countryCallingCode => countryCallingCode.disabled"
        @change="updateFullPhoneNumber"
      />
    </v-col>
    <v-col
      cols="7"
      md="9"
      xl="7"
    >
      <v-text-field
        v-model="nationalNumber"
        outlined
        :rules="validationRules"
        :label="label || 'Telefonnummer'"
        hint="Format 753112345"
        type="tel"
        hide-details="auto"
        :disabled="disabled"
        :validate-on-blur="validateOnBlur"
        :error-messages="errorMessage"
        :loading="loading"
        @blur="updateFullPhoneNumber"
        @input="updateFullPhoneNumberOnChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import { phoneStripped } from '@/lib/validation'
import codes from '@/lib/countryCallingCodes'

export default {
  inject: ['companyBaseData'],

  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: ''
    },
    validateOnBlur: {
      type: Boolean,
      default: false
    },
    updateOnInput: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    labelCountryCallingCode: {
      type: String,
      default: () => 'Ländervorwahl'
    }
  },
  data () {
    return {
      countryCallingCode: '',
      nationalNumber: '',
      validationRules: [phoneStripped, ...this.rules],
      codes
    }
  },
  computed: {
    countryCallingCodes () {
      const mostRelevantCountryCodes = ['DE', 'AT', 'CH']
      const mostRelevantEntries = []
      const countries = codes
        .map(country => {
          const entry = { countryCode: country.countryCode, countryCallingCode: country.callingCode, displayText: `${country.callingCode} (${country.countryCode})`, disabled: false }
          if (mostRelevantCountryCodes.includes(country.countryCode)) {
            mostRelevantEntries.push(entry)
            return null
          }
          return entry
        })
        .filter(x => x !== null)
        .sort((a, b) => a.countryCode.localeCompare(b.countryCode))

      const seperator = { countryCode: '-', countryCallingCode: '-', displayText: '────', disabled: true }
      countries.unshift(seperator)
      countries.unshift(...mostRelevantEntries)

      return countries
    }
  },
  watch: {
    value () {
      this.setSplittedValues()
    }
  },
  created () {
    this.setSplittedValues()
  },
  methods: {
    setSplittedValues () {
      const { countryCallingCode, nationalNumber } = this.getSplittedPhoneNumber()
      this.countryCallingCode = countryCallingCode.trim()
      this.nationalNumber = nationalNumber.trim()
    },

    getSplittedPhoneNumber () {
      if (this.value) {
        return this.parseValueToSplittedFormat()
      }

      const countryCallingCode = this.getCountryCallingCodeForCompany()

      return {
        countryCallingCode,
        nationalNumber: ''
      }
    },

    updateFullPhoneNumberOnChange () {
      if (this.updateOnInput) {
        this.updateFullPhoneNumber()
      }
    },

    updateFullPhoneNumber () {
      const symbol = this.countryCallingCode && '+'
      let combinedPhone = ''
      if (this.nationalNumber) {
        combinedPhone = `${symbol}${this.countryCallingCode}${this.nationalNumber}`.trim()
      }
      this.$emit('input', combinedPhone)
    },

    parseValueToSplittedFormat () {
      const mappedCodes = this.countryCallingCodes.map(entry => entry.countryCallingCode).join('|')
      var regex = new RegExp('^((00|\\+)(?<countryCallingCode>' + mappedCodes + '))?(?<nationalNumber>.*)')

      const { groups } = this.value.match(regex)
      const { countryCallingCode = '', nationalNumber = this.value } = groups

      return {
        countryCallingCode,
        nationalNumber
      }
    },

    getCountryCallingCodeForCompany () {
      let code = this.countryCallingCodes[0].countryCallingCode
      if (this.companyBaseData && this.companyBaseData.country) {
        code = this.countryCallingCodes.find((country) => (country.countryCode === this.companyBaseData.country.toUpperCase())).countryCallingCode
      }
      return code
    }

  }

}
</script>
