export const codes = [
  {
    callingCode: '93',
    countryCode: 'AF'
  },
  {
    callingCode: '355',
    countryCode: 'AL'
  },
  {
    callingCode: '213',
    countryCode: 'DZ'
  },
  {
    callingCode: '1-684',
    countryCode: 'AS'
  },
  {
    callingCode: '376',
    countryCode: 'AD'
  },
  {
    callingCode: '244',
    countryCode: 'AO'
  },
  {
    callingCode: '1-264',
    countryCode: 'AI'
  },
  {
    callingCode: '672',
    countryCode: 'AQ'
  },
  {
    callingCode: '1-268',
    countryCode: 'AG'
  },
  {
    callingCode: '54',
    countryCode: 'AR'
  },
  {
    callingCode: '374',
    countryCode: 'AM'
  },
  {
    callingCode: '297',
    countryCode: 'AW'
  },
  {
    callingCode: '61',
    countryCode: 'AU'
  },
  {
    callingCode: '43',
    countryCode: 'AT'
  },
  {
    callingCode: '994',
    countryCode: 'AZ'
  },
  {
    callingCode: '1-242',
    countryCode: 'BS'
  },
  {
    callingCode: '973',
    countryCode: 'BH'
  },
  {
    callingCode: '880',
    countryCode: 'BD'
  },
  {
    callingCode: '1-246',
    countryCode: 'BB'
  },
  {
    callingCode: '375',
    countryCode: 'BY'
  },
  {
    callingCode: '32',
    countryCode: 'BE'
  },
  {
    callingCode: '501',
    countryCode: 'BZ'
  },
  {
    callingCode: '229',
    countryCode: 'BJ'
  },
  {
    callingCode: '1-441',
    countryCode: 'BM'
  },
  {
    callingCode: '975',
    countryCode: 'BT'
  },
  {
    callingCode: '591',
    countryCode: 'BO'
  },
  {
    callingCode: '387',
    countryCode: 'BA'
  },
  {
    callingCode: '267',
    countryCode: 'BW'
  },
  {
    callingCode: '55',
    countryCode: 'BR'
  },
  {
    callingCode: '246',
    countryCode: 'IO'
  },
  {
    callingCode: '1-284',
    countryCode: 'VG'
  },
  {
    callingCode: '673',
    countryCode: 'BN'
  },
  {
    callingCode: '359',
    countryCode: 'BG'
  },
  {
    callingCode: '226',
    countryCode: 'BF'
  },
  {
    callingCode: '257',
    countryCode: 'BI'
  },
  {
    callingCode: '855',
    countryCode: 'KH'
  },
  {
    callingCode: '237',
    countryCode: 'CM'
  },
  {
    callingCode: '1',
    countryCode: 'CA'
  },
  {
    callingCode: '238',
    countryCode: 'CV'
  },
  {
    callingCode: '1-345',
    countryCode: 'KY'
  },
  {
    callingCode: '236',
    countryCode: 'CF'
  },
  {
    callingCode: '235',
    countryCode: 'TD'
  },
  {
    callingCode: '56',
    countryCode: 'CL'
  },
  {
    callingCode: '86',
    countryCode: 'CN'
  },
  {
    callingCode: '61',
    countryCode: 'CX'
  },
  {
    callingCode: '61',
    countryCode: 'CC'
  },
  {
    callingCode: '57',
    countryCode: 'CO'
  },
  {
    callingCode: '269',
    countryCode: 'KM'
  },
  {
    callingCode: '682',
    countryCode: 'CK'
  },
  {
    callingCode: '506',
    countryCode: 'CR'
  },
  {
    callingCode: '385',
    countryCode: 'HR'
  },
  {
    callingCode: '53',
    countryCode: 'CU'
  },
  {
    callingCode: '599',
    countryCode: 'CW'
  },
  {
    callingCode: '357',
    countryCode: 'CY'
  },
  {
    callingCode: '420',
    countryCode: 'CZ'
  },
  {
    callingCode: '243',
    countryCode: 'CD'
  },
  {
    callingCode: '45',
    countryCode: 'DK'
  },
  {
    callingCode: '253',
    countryCode: 'DJ'
  },
  {
    callingCode: '1-767',
    countryCode: 'DM'
  },
  {
    callingCode: '1-809',
    countryCode: 'DO'
  },
  {
    callingCode: '670',
    countryCode: 'TL'
  },
  {
    callingCode: '593',
    countryCode: 'EC'
  },
  {
    callingCode: '20',
    countryCode: 'EG'
  },
  {
    callingCode: '503',
    countryCode: 'SV'
  },
  {
    callingCode: '240',
    countryCode: 'GQ'
  },
  {
    callingCode: '291',
    countryCode: 'ER'
  },
  {
    callingCode: '372',
    countryCode: 'EE'
  },
  {
    callingCode: '251',
    countryCode: 'ET'
  },
  {
    callingCode: '500',
    countryCode: 'FK'
  },
  {
    callingCode: '298',
    countryCode: 'FO'
  },
  {
    callingCode: '679',
    countryCode: 'FJ'
  },
  {
    callingCode: '358',
    countryCode: 'FI'
  },
  {
    callingCode: '33',
    countryCode: 'FR'
  },
  {
    callingCode: '689',
    countryCode: 'PF'
  },
  {
    callingCode: '241',
    countryCode: 'GA'
  },
  {
    callingCode: '220',
    countryCode: 'GM'
  },
  {
    callingCode: '995',
    countryCode: 'GE'
  },
  {
    callingCode: '49',
    countryCode: 'DE'
  },
  {
    callingCode: '233',
    countryCode: 'GH'
  },
  {
    callingCode: '350',
    countryCode: 'GI'
  },
  {
    callingCode: '30',
    countryCode: 'GR'
  },
  {
    callingCode: '299',
    countryCode: 'GL'
  },
  {
    callingCode: '1-473',
    countryCode: 'GD'
  },
  {
    callingCode: '1-671',
    countryCode: 'GU'
  },
  {
    callingCode: '502',
    countryCode: 'GT'
  },
  {
    callingCode: '44-1481',
    countryCode: 'GG'
  },
  {
    callingCode: '224',
    countryCode: 'GN'
  },
  {
    callingCode: '245',
    countryCode: 'GW'
  },
  {
    callingCode: '592',
    countryCode: 'GY'
  },
  {
    callingCode: '509',
    countryCode: 'HT'
  },
  {
    callingCode: '504',
    countryCode: 'HN'
  },
  {
    callingCode: '852',
    countryCode: 'HK'
  },
  {
    callingCode: '36',
    countryCode: 'HU'
  },
  {
    callingCode: '354',
    countryCode: 'IS'
  },
  {
    callingCode: '91',
    countryCode: 'IN'
  },
  {
    callingCode: '62',
    countryCode: 'ID'
  },
  {
    callingCode: '98',
    countryCode: 'IR'
  },
  {
    callingCode: '964',
    countryCode: 'IQ'
  },
  {
    callingCode: '353',
    countryCode: 'IE'
  },
  {
    callingCode: '44-1624',
    countryCode: 'IM'
  },
  {
    callingCode: '972',
    countryCode: 'IL'
  },
  {
    callingCode: '39',
    countryCode: 'IT'
  },
  {
    callingCode: '225',
    countryCode: 'CI'
  },
  {
    callingCode: '1-876',
    countryCode: 'JM'
  },
  {
    callingCode: '81',
    countryCode: 'JP'
  },
  {
    callingCode: '44-1534',
    countryCode: 'JE'
  },
  {
    callingCode: '962',
    countryCode: 'JO'
  },
  {
    callingCode: '7',
    countryCode: 'KZ'
  },
  {
    callingCode: '254',
    countryCode: 'KE'
  },
  {
    callingCode: '686',
    countryCode: 'KI'
  },
  {
    callingCode: '383',
    countryCode: 'XK'
  },
  {
    callingCode: '965',
    countryCode: 'KW'
  },
  {
    callingCode: '996',
    countryCode: 'KG'
  },
  {
    callingCode: '856',
    countryCode: 'LA'
  },
  {
    callingCode: '371',
    countryCode: 'LV'
  },
  {
    callingCode: '961',
    countryCode: 'LB'
  },
  {
    callingCode: '266',
    countryCode: 'LS'
  },
  {
    callingCode: '231',
    countryCode: 'LR'
  },
  {
    callingCode: '218',
    countryCode: 'LY'
  },
  {
    callingCode: '423',
    countryCode: 'LI'
  },
  {
    callingCode: '370',
    countryCode: 'LT'
  },
  {
    callingCode: '352',
    countryCode: 'LU'
  },
  {
    callingCode: '853',
    countryCode: 'MO'
  },
  {
    callingCode: '389',
    countryCode: 'MK'
  },
  {
    callingCode: '261',
    countryCode: 'MG'
  },
  {
    callingCode: '265',
    countryCode: 'MW'
  },
  {
    callingCode: '60',
    countryCode: 'MY'
  },
  {
    callingCode: '960',
    countryCode: 'MV'
  },
  {
    callingCode: '223',
    countryCode: 'ML'
  },
  {
    callingCode: '356',
    countryCode: 'MT'
  },
  {
    callingCode: '692',
    countryCode: 'MH'
  },
  {
    callingCode: '222',
    countryCode: 'MR'
  },
  {
    callingCode: '230',
    countryCode: 'MU'
  },
  {
    callingCode: '262',
    countryCode: 'YT'
  },
  {
    callingCode: '52',
    countryCode: 'MX'
  },
  {
    callingCode: '691',
    countryCode: 'FM'
  },
  {
    callingCode: '373',
    countryCode: 'MD'
  },
  {
    callingCode: '377',
    countryCode: 'MC'
  },
  {
    callingCode: '976',
    countryCode: 'MN'
  },
  {
    callingCode: '382',
    countryCode: 'ME'
  },
  {
    callingCode: '1-664',
    countryCode: 'MS'
  },
  {
    callingCode: '212',
    countryCode: 'MA'
  },
  {
    callingCode: '258',
    countryCode: 'MZ'
  },
  {
    callingCode: '95',
    countryCode: 'MM'
  },
  {
    callingCode: '264',
    countryCode: 'NA'
  },
  {
    callingCode: '674',
    countryCode: 'NR'
  },
  {
    callingCode: '977',
    countryCode: 'NP'
  },
  {
    callingCode: '31',
    countryCode: 'NL'
  },
  {
    callingCode: '599',
    countryCode: 'AN'
  },
  {
    callingCode: '687',
    countryCode: 'NC'
  },
  {
    callingCode: '64',
    countryCode: 'NZ'
  },
  {
    callingCode: '505',
    countryCode: 'NI'
  },
  {
    callingCode: '227',
    countryCode: 'NE'
  },
  {
    callingCode: '234',
    countryCode: 'NG'
  },
  {
    callingCode: '683',
    countryCode: 'NU'
  },
  {
    callingCode: '850',
    countryCode: 'KP'
  },
  {
    callingCode: '1-670',
    countryCode: 'MP'
  },
  {
    callingCode: '47',
    countryCode: 'NO'
  },
  {
    callingCode: '968',
    countryCode: 'OM'
  },
  {
    callingCode: '92',
    countryCode: 'PK'
  },
  {
    callingCode: '680',
    countryCode: 'PW'
  },
  {
    callingCode: '970',
    countryCode: 'PS'
  },
  {
    callingCode: '507',
    countryCode: 'PA'
  },
  {
    callingCode: '675',
    countryCode: 'PG'
  },
  {
    callingCode: '595',
    countryCode: 'PY'
  },
  {
    callingCode: '51',
    countryCode: 'PE'
  },
  {
    callingCode: '63',
    countryCode: 'PH'
  },
  {
    callingCode: '64',
    countryCode: 'PN'
  },
  {
    callingCode: '48',
    countryCode: 'PL'
  },
  {
    callingCode: '351',
    countryCode: 'PT'
  },
  {
    callingCode: '1-787',
    countryCode: 'PR'
  },
  {
    callingCode: '974',
    countryCode: 'QA'
  },
  {
    callingCode: '242',
    countryCode: 'CG'
  },
  {
    callingCode: '262',
    countryCode: 'RE'
  },
  {
    callingCode: '40',
    countryCode: 'RO'
  },
  {
    callingCode: '7',
    countryCode: 'RU'
  },
  {
    callingCode: '250',
    countryCode: 'RW'
  },
  {
    callingCode: '590',
    countryCode: 'BL'
  },
  {
    callingCode: '290',
    countryCode: 'SH'
  },
  {
    callingCode: '1-869',
    countryCode: 'KN'
  },
  {
    callingCode: '1-758',
    countryCode: 'LC'
  },
  {
    callingCode: '590',
    countryCode: 'MF'
  },
  {
    callingCode: '508',
    countryCode: 'PM'
  },
  {
    callingCode: '1-784',
    countryCode: 'VC'
  },
  {
    callingCode: '685',
    countryCode: 'WS'
  },
  {
    callingCode: '378',
    countryCode: 'SM'
  },
  {
    callingCode: '239',
    countryCode: 'ST'
  },
  {
    callingCode: '966',
    countryCode: 'SA'
  },
  {
    callingCode: '221',
    countryCode: 'SN'
  },
  {
    callingCode: '381',
    countryCode: 'RS'
  },
  {
    callingCode: '248',
    countryCode: 'SC'
  },
  {
    callingCode: '232',
    countryCode: 'SL'
  },
  {
    callingCode: '65',
    countryCode: 'SG'
  },
  {
    callingCode: '1-721',
    countryCode: 'SX'
  },
  {
    callingCode: '421',
    countryCode: 'SK'
  },
  {
    callingCode: '386',
    countryCode: 'SI'
  },
  {
    callingCode: '677',
    countryCode: 'SB'
  },
  {
    callingCode: '252',
    countryCode: 'SO'
  },
  {
    callingCode: '27',
    countryCode: 'ZA'
  },
  {
    callingCode: '82',
    countryCode: 'KR'
  },
  {
    callingCode: '211',
    countryCode: 'SS'
  },
  {
    callingCode: '34',
    countryCode: 'ES'
  },
  {
    callingCode: '94',
    countryCode: 'LK'
  },
  {
    callingCode: '249',
    countryCode: 'SD'
  },
  {
    callingCode: '597',
    countryCode: 'SR'
  },
  {
    callingCode: '47',
    countryCode: 'SJ'
  },
  {
    callingCode: '268',
    countryCode: 'SZ'
  },
  {
    callingCode: '46',
    countryCode: 'SE'
  },
  {
    callingCode: '41',
    countryCode: 'CH'
  },
  {
    callingCode: '963',
    countryCode: 'SY'
  },
  {
    callingCode: '886',
    countryCode: 'TW'
  },
  {
    callingCode: '992',
    countryCode: 'TJ'
  },
  {
    callingCode: '255',
    countryCode: 'TZ'
  },
  {
    callingCode: '66',
    countryCode: 'TH'
  },
  {
    callingCode: '228',
    countryCode: 'TG'
  },
  {
    callingCode: '690',
    countryCode: 'TK'
  },
  {
    callingCode: '676',
    countryCode: 'TO'
  },
  {
    callingCode: '1-868',
    countryCode: 'TT'
  },
  {
    callingCode: '216',
    countryCode: 'TN'
  },
  {
    callingCode: '90',
    countryCode: 'TR'
  },
  {
    callingCode: '993',
    countryCode: 'TM'
  },
  {
    callingCode: '1-649',
    countryCode: 'TC'
  },
  {
    callingCode: '688',
    countryCode: 'TV'
  },
  {
    callingCode: '1-340',
    countryCode: 'VI'
  },
  {
    callingCode: '256',
    countryCode: 'UG'
  },
  {
    callingCode: '380',
    countryCode: 'UA'
  },
  {
    callingCode: '971',
    countryCode: 'AE'
  },
  {
    callingCode: '44',
    countryCode: 'GB'
  },
  {
    callingCode: '1',
    countryCode: 'US'
  },
  {
    callingCode: '598',
    countryCode: 'UY'
  },
  {
    callingCode: '998',
    countryCode: 'UZ'
  },
  {
    callingCode: '678',
    countryCode: 'VU'
  },
  {
    callingCode: '379',
    countryCode: 'VA'
  },
  {
    callingCode: '58',
    countryCode: 'VE'
  },
  {
    callingCode: '84',
    countryCode: 'VN'
  },
  {
    callingCode: '681',
    countryCode: 'WF'
  },
  {
    callingCode: '212',
    countryCode: 'EH'
  },
  {
    callingCode: '967',
    countryCode: 'YE'
  },
  {
    callingCode: '260',
    countryCode: 'ZM'
  },
  {
    callingCode: '263',
    countryCode: 'ZW'
  }
]

export default codes
