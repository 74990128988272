<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model.trim="leadData.firstname"
        outlined
        :rules="[rules.required]"
        validate-on-blur
        label="Vorname*"
        hide-details="auto"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model.trim="leadData.lastname"
        outlined
        :rules="[rules.required]"
        validate-on-blur
        label="Name*"
        hide-details="auto"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model.trim="leadData.email"
        outlined
        :rules="[rules.required, rules.email]"
        validate-on-blur
        label="E-Mail-Adresse*"
        type="email"
        hide-details="auto"
      />
    </v-col>
    <v-col cols="12">
      <PhoneField
        v-model="leadData.phone"
      />
      <p><sup>*</sup>= Pflichtfeld</p>
    </v-col>
  </v-row>
</template>
<script>
import { required, email } from '@/lib/validation'
import PhoneField from '@/components/PhoneField.vue'
export default {
  components: {
    PhoneField
  },
  props: {
    leadData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    rules () {
      return {
        email,
        required
      }
    }
  }
}
</script>
